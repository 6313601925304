<template>
  <div class="fill">
    <div class="description">抱歉，您无权限访问该页面</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.fill {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .description {
    margin-top: 24px;
    font-size: 16px;
    color: #333333;
    line-height: 24px;
  }
}
</style>
